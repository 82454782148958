.textSequence {
  position: relative;
  display: inline-block;
  z-index: 1;
}

/* Default for Inter */
.textSequence[data-font-family='Inter']::before,
.textSequence[data-font-family='Inter']::after {
  content: attr(data-text);
  position: absolute;
  left: 4px;
  top: 0;
  -webkit-text-stroke: 10px var(--stroke-color);
  z-index: -2;
  color: transparent; /* Ensures the stroke is visible */
}

/* For all other fonts */
.textSequence[data-font-family='Anton']::before,
.textSequence[data-font-family='Anton']::after {
  content: attr(data-text);
  position: absolute;
  left: 10px;
  top: 10px;
  -webkit-text-stroke: 8px var(--stroke-color);
  z-index: -2;
  color: transparent;
}

.textSequence > * {
  position: relative;
  z-index: -1;
}
