@charset "UTF-8";

$Black: #000000;
$MainBlue: #292d8d;
$OffWhite: #f0f0f0;
$NewWhite: #f9f9f9;
// $White: #ffffff;
$Peach: #f6a98f;
$AccentCoral: #ee6061;
$AlertRed: #ff0e0e;
$LightGrey: #a9a9a9;
$MediumGrey: #555555;
$DarkGrey: #333333;
$BrightYellow: #fcbf49;

/* 
 *
 *  300 = Book
 *  500 = Medium
 *  700 = Bold
 *  900 = Black
 * 
 * */

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-BlackItalic.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../fonts/CircularStd-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PoetsenOne';
  src: url('../fonts/PoetsenOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

/****************************/
/** Reset **/
/****************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
.error-comment,
h4,
h5,
h6,
.panel-title,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: block;
  overflow: hidden;
}

body {
  color: #333;
  letter-spacing: normal;
  font-family: CircularStd, sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  background: #ffffff !important;
  white-space: pre-wrap;
  position: relative;
  width: 100vw;
  height: 100vh;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}

*:not(svg > *, svg, span) {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

#__next {
  background: #fff;
}

textarea {
  resize: none;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a:hover,
a:active {
  outline: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button:focus {
  border: none;
  outline: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333333;
}

a:hover {
  text-decoration: none;
  color: #333333;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #333333;
}

img {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

div[data-highlight='main'] *::selection {
  background: #fde68a;
}

div[data-highlight='main'] .highlighted-from-divider {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-left: 4px;
}

div[data-highlight='main'] .highlighted-to-divider {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 4px;
  margin-right: 6px;
}

div[data-highlight='main'] .highlighted {
  cursor: pointer;
  background-color: #FDE68A;
  &:not(.highlighted-from-divider, .highlighted-to-divider) {
    &.highlight-hover {
      border-radius: 0;
    }
    &.highlight-focus {
      border-radius: 0;
    }
  }
}

div[data-highlight='main'] .on-highlighted {
  background: #fde68a;
}

div[data-highlight='main'] > div {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.highlight-divider {
  color: #FDE68A;
  &.highlight-hover {
    color: #f59e0b;
  }
  &.highlight-focus {
    color: #f59e0b;
  }
}

.highlight-hover {
  background: #FDE68A !important;
  border-top: 2px solid #FBBF24!important ;
  border-bottom: 2px solid #FBBF24!important;
  &.highlighted-from-divider {
    border-left: 2px solid #FBBF24!important;
  }

  &.highlighted-to-divider {
    border-right: 2px solid #FBBF24!important;
  }
  &:not(.highlighted-from-divider):not(.highlighted-to-divider) {
    border-radius: 0px !important;
  }
}

.highlight-focus {
  border-top: 2px solid #f4d24f !important;
  border-bottom: 2px solid #f4d24f !important;

  border-left: 0;
  border-right: 0;

  &.highlighted-from-divider {
    border-left: 2px solid #f4d24f !important;
  }

  &.highlighted-to-divider {
    border-right: 2px solid #f4d24f !important;
  }
  &:not(.highlighted-from-divider){
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  &:not(.highlighted-to-divider) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  background-color: #FEF3C7 !important;
}

.highlighted-from-divider {
  border-left: 2px solid white;
}

.highlighted-to-divider {
  border-right: 2px solid white;
}

.currently-playing { 
  background-color: #C4D5FF!important;
  &:not(.highlighted){
    border-radius: 0.375rem;
  }
}

.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-200;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-300;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
