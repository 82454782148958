.text-box {
  display: inline-block;
  position: relative;
}

.text-box div {
  display: inline-block;
  top: -200px;
  transform: rotateX(-90deg);
  opacity: 0;
  animation-timing-function: ease;
}

.text-box div:nth-child(1) {
  animation: rollDown 2.5s forwards infinite;
}

@keyframes rollDown {
  0% {
    transform: rotateX(-30deg) translateY(10px);
    opacity: 0;
  }
  10% {
    transform: rotateX(0deg) translateY(0px);
    opacity: 1;
  }
  90% {
    transform: rotateX(0deg) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: rotateX(30deg) translateY(-100px);
    opacity: 0;
  }
}
