.react-multiple-carousel__arrow--right::before {
    content: none !important;
}

.react-multiple-carousel__arrow--left::before {
    content: none !important;
}

.react-multiple-carousel__arrow {
    min-width: 0 !important;
    min-height: 0 !important;
}