.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.fullscreen-recorder-answer-modal .ReactModal__Overlay {
  transform: translateX(-100%);
}
